import React from "react";

import Layout from "../components/layout";
import NotFound from "../views/notFound";

const NotFoundPage = () => {
  return (
    <Layout hasNotFound>
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;
