import React from "react";
import { navigate } from "gatsby";

import {
  ActionButton,
  Content,
  Description,
  SubTitle,
  Title,
  Wrapper,
} from "./styles";

const NotFound = () => {
  return (
    <Wrapper>
      <Content>
        <Title component="h1">404</Title>
        <SubTitle component="h3">Página não encontrada</SubTitle>
        <Description component="span">
          A página que você está procurando foi excluída ou o link acessado está
          errado.
        </Description>
        <ActionButton onClick={() => navigate(-1)}>
          Voltar para o início
        </ActionButton>
      </Content>
    </Wrapper>
  );
};

export default NotFound;
