import styled from "styled-components";
import Button from "../../components/button";
import Heading from "../../components/heading";
import Text from "../../components/text";

import { COLORS } from "../../constants/colors";

export const Wrapper = styled.section`
  background-color: ${COLORS.WHITE};
  width: 100%;
  height: calc(100vh - 6rem);
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 24px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 547.63px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 32px;
`;

export const Title = styled(Heading)`
  font-size: 6em;
  line-height: 114px;

  ${({ theme }) => theme.media.x`
    line-height: 68px;
  `}

  ${({ theme }) => theme.media.md`
    line-height: 47px;
  `}
`;

export const SubTitle = styled(Heading)`
  font-family: "Inter";
  color: ${COLORS.GRAY.MAIN};
`;

export const Description = styled(Text)`
  font-size: 1.25em;

  ${({ theme }) => theme.media.x`
    line-height: 47px;
  `}

  ${({ theme }) => theme.media.md`
    line-height: 34px;
  `}
`;

export const ActionButton = styled(Button)`
  background-color: ${COLORS.ORANGE};
  color: ${COLORS.WHITE};
`;
